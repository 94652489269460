import React, {useEffect, useState} from 'react';
import Xgplayer from 'xgplayer-react';
import heic2any from "heic2any";
import { saveAs } from 'file-saver';
import Viewer from 'react-viewer';

import Header from './Header';
import Footer from './Footer';
import PdfView from './PdfView';
import Component404 from './Component404';
import * as Helper from '../Helper'

import "./App.css"

function App() {
  const [content, setContent] = useState("")
  const [ visibleImg, setVisibleImg ] = useState(true);

  useEffect(() => {
    (async () => {
      let bucket_list = [
        {"bucket_type": "build-aptive-photos-dev", "cloudfront_id": "d2ayj90zf30fdh"},
        {"bucket_type": "build-aptive-photos", "cloudfront_id": "d34nv8f03icj5q"},
        {"bucket_type": "build-aptive-files", "cloudfront_id": "d1e4w0s381puqo"},
        {"bucket_type": "build-aptive-files-dev", "cloudfront_id": "d2vm01oy050k8e"},
        {"bucket_type": "build-aptive-reports-public", "cloudfront_id": "d33h1b16l1kzee"},
      ]

      let pathname = window.location.pathname
      pathname = pathname.replace('index.html', '');
      pathname = pathname.replace(/^\/+|\/+$/g, '');
      pathname = pathname.replace('//', '/')
      let pathname_original = pathname
      let pathname_split = pathname.split('/')
      let cloudfront_url = ""
      let cloudfront_url_original = ""
      
      if(pathname_split.length > 1){
        let bucket_type = pathname_split[0]

        for (const bucket_item in bucket_list){
          let bucket_item_type = bucket_list[bucket_item]['bucket_type']
          let cloudfront_id = bucket_list[bucket_item]['cloudfront_id']

          if (bucket_type === bucket_item_type){
            pathname = pathname.replace(bucket_type, '')
            pathname = pathname.replace(/^\/+|\/+$/g, '');
            cloudfront_url = `https://${cloudfront_id}.cloudfront.net/${pathname}`
            break
          }
        }
      }

      //File ext
      const IMAGE_MIMETYPE = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.heic', '.HEIC', '.heif', '.HEIF']
      const VIDEO_MIMETYPE = ['.mp4', '.MP4', '.mov', '.MOV']
      const PDF_MIMETYPE = ['.pdf']

      let file_ext = pathname.slice(pathname.lastIndexOf("."))
      let is_image = IMAGE_MIMETYPE.includes(file_ext)
      let is_video = VIDEO_MIMETYPE.includes(file_ext)
      let is_pdf = PDF_MIMETYPE.includes(file_ext)
      let w_height = window.innerHeight
      let header_height = document.getElementById("header-wrap").offsetHeight
      let mse_height = w_height - header_height

      let set_height = () => {
        w_height = window.innerHeight
        header_height = document.getElementById("header-wrap").offsetHeight
        mse_height = w_height - header_height

        document.getElementById("mse").style.height = `${mse_height}px`;
        document.getElementById("mse").style.marginTop = `${header_height}px`;
      }

      set_height()
      window.addEventListener("resize", (event) => {
        set_height()
      });

      let has_media = false 

      if (cloudfront_url){
        let data = await Helper.isValidMedia(cloudfront_url)
        if (data === true){
          cloudfront_url_original = cloudfront_url

          if (is_video){
            let thumbnail_url = `https://dc9fqjigym1sj.cloudfront.net/${pathname_original}`
            let config = {
              id: 'mse',
              url: cloudfront_url,
              height: `${mse_height}px`,
              width: '100%',
              poster: thumbnail_url
            };
            let Player = null;

            setContent(<Xgplayer config={config} playerInit={(player)=>{ Player = player; }} />)

            has_media = true
          }else if (is_image){
            let heic_format = [ '.heic', '.HEIC', '.heif', '.HEIF']

            if (heic_format.includes(file_ext)){
              let res = await fetch(cloudfront_url)
              let blob = await res.blob()
              let conversionResult = await heic2any({blob})
              cloudfront_url = URL.createObjectURL(conversionResult);
              
            }
            
            if (cloudfront_url){
              const str_content = (
                <div className='flex justify-center items-center w-full h-full'>
                  <Viewer
                    visible={visibleImg}
                    onClose={() => { setVisibleImg(false); } }
                    images={[{src: cloudfront_url, alt: ''}]}
                    noClose={true}
                    showTotal={false}
                    changeable={false}
                    noImgDetails={true}
                    noNavbar={true}
                    downloadable={false}
                    />
                </div>
              )
              setContent(str_content)
              has_media = true
            }
            
          }else if (is_pdf){
            document.getElementsByTagName("html")[0].style.overflowY = 'hidden'
            document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
            console.log(cloudfront_url)
            // setContent(<PdfView width="100%" height={mse_height} fileUrl={cloudfront_url}></PdfView>)
            const full_url = `${window.location.protocol}//${window.location.hostname}${window.location.port?":"+window.location.port:""}/pdfjs/web/viewer.html?file=${cloudfront_url}`
            // console.log(full_url)
            // console.log(window.location)
            setContent(<iframe src={full_url} width="100%" height="100%"></iframe>)

            has_media = true

            document.addEventListener("click", function(e){
              if (e.target?.localName == 'a' && e.target?.dataset?.target == "external") {
                e.preventDefault()
                e.stopPropagation();
                var url = e.target.href;

                // You can place extra checks here.
                var tab = window.open(url, '_blank');
                tab.focus();
              }
            }, true)
          }
        }
      }

      if (!has_media){
        setContent(<Component404></Component404>)
      }else{
        

        let elements = document.getElementsByClassName('btn-download')
        for(var x=0; x < elements.length; x++){
            elements[x].addEventListener("click", (event) => {
              event.preventDefault()

              let fileName = Helper.getFileName(cloudfront_url_original);
              saveAs(cloudfront_url_original, fileName);
            });
        }
      }


    })()
    
  }, []);

  const spin_style = {
    "maxWidth": "100px"
  }

  return (
    <div className="App">
      <Header></Header>
      
      <div id="mse" className='flex justify-center items-center'>
        <img style={spin_style} className={content ? "hidden" : ""} src="/images/common/spin.svg"></img>
        {content}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
