export async function isImgUrl(url) {
  const img = new Image();
  img.src = url;
  return await new Promise((resolve, reject) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

export async function isValidMedia(media_url) {
  const res = await fetch(media_url, { method: 'HEAD' });
  if (res.ok == true){
    return true
  }

  return false
}  

export function getFileName(str) {
  return str.substring(str.lastIndexOf('/') + 1)
}